import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="153.6"
        height="32.76"
        viewBox="0 0 153.6 32.76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
      <g>
          <circle cx="17.08" cy="2.33" r="2.33" fill={fill} />
          <circle cx="8.48" cy="2.33" r="2.33" fill={fill} />
          <circle cx="25.67" cy="2.33" r="2.33" fill={fill} />
          <g>
              <path d="M361.08,293.25a5.91,5.91,0,0,1,4.94,9.13l-4.4,6.75a5.91,5.91,0,0,1-9.89,0l-4.4-6.75a5.9,5.9,0,0,1,4.94-9.13h8.81m0-2.21h-8.81a8.11,8.11,0,0,0-6.79,12.54l4.4,6.76a8.11,8.11,0,0,0,13.59,0l4.4-6.76A8.11,8.11,0,0,0,361.08,291Z" transform="translate(-344.14 -281.26)" fill={fill} />
          </g>
          <g>
              <path d="M370.17,293.25a5.91,5.91,0,0,1,4.95,9.13l-4.41,6.75a5.9,5.9,0,0,1-9.88,0l-4.4-6.75a5.9,5.9,0,0,1,4.94-9.13h8.8m0-2.21h-8.8a8.11,8.11,0,0,0-6.8,12.54l4.4,6.76a8.12,8.12,0,0,0,13.6,0l4.4-6.76a8.11,8.11,0,0,0-6.8-12.54Z" transform="translate(-344.14 -281.26)" fill={fill} />
          </g>
          <path d="M402.17,298.56a1,1,0,0,1,1.06-1,1,1,0,0,1,1.09,1v5c0,3.79-2.07,4.85-4.21,4.85a3.64,3.64,0,0,1-3.69-2.27,3.56,3.56,0,0,1-3.67,2.27c-2.12,0-4.21-1-4.21-4.85v-5a1,1,0,0,1,1.06-1,1,1,0,0,1,1.09,1v5.08c0,2.22,1.16,2.89,2.32,2.89s2.32-.67,2.32-2.89v-5.08a1,1,0,0,1,1.09-1,1,1,0,0,1,1.06,1v5.08c0,2.22,1.21,2.89,2.37,2.89s2.32-.67,2.32-2.89Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M409.56,303.8c.33,1.68,1.73,2.76,4.1,2.76a5.81,5.81,0,0,0,3.23-.9.85.85,0,0,1,1.21.87,1,1,0,0,1-.36.78,7.37,7.37,0,0,1-4.39,1.16c-3.77,0-6-2.25-6-5.5s2-5.68,5.88-5.68c2.9,0,4.94,1.42,4.94,3.85s-1.89,3.69-4.63,3.69A6.82,6.82,0,0,1,409.56,303.8Zm0-1.78a7.65,7.65,0,0,0,3.71.9c1.92,0,2.84-.72,2.84-1.83s-1-1.94-2.81-1.94C411,299.15,409.84,300.39,409.56,302Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M421.15,290.53a1.07,1.07,0,0,1,2.14,0v8.72a4.87,4.87,0,0,1,4.18-2c3.59,0,5.45,2.56,5.45,5.58,0,3.28-2.14,5.78-5.94,5.78a5.42,5.42,0,0,1-5.83-5.68ZM427,306.82a3.52,3.52,0,0,0,3.69-3.8,3.58,3.58,0,0,0-3.72-3.87,3.53,3.53,0,0,0-3.66,3.87A3.49,3.49,0,0,0,427,306.82Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M441.88,293.83a1.3,1.3,0,0,1,2.6,0,1.3,1.3,0,0,1-2.6,0Zm.2,12v-7.25c0-.7.42-1,1.09-1a.93.93,0,0,1,1.06,1v6.81c0,.8.23,1.09.75,1.11a.93.93,0,0,1,.64,1c-.05.46-.36.9-1.21.9C443,308.39,442.08,307.52,442.08,305.81Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M448.25,303.26c0-4.14,2.2-6,5.84-6a1,1,0,0,1,0,2.09c-2.53,0-3.72,1.71-3.72,4v4a1.06,1.06,0,0,1-2.12,0Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M461.65,308.65c-3.59,0-6-2.32-6-5.68s2.4-5.68,6-5.68,6,2.32,6,5.71S465.24,308.65,461.65,308.65Zm0-9.53a3.85,3.85,0,0,0,0,7.7,3.85,3.85,0,0,0,0-7.7Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M471.77,308.39a1,1,0,0,1-1.06-1V290.53a1,1,0,0,1,1.06-1,1,1,0,0,1,1.09,1v8.67a4.2,4.2,0,0,1,3.89-1.91c2.87,0,4.65,1.52,4.65,5.5v4.57a1,1,0,0,1-1.06,1,1,1,0,0,1-1.08-1v-4.6c0-2.34-1-3.56-3.13-3.56a3,3,0,0,0-3.27,3v5.19A1,1,0,0,1,471.77,308.39Z" transform="translate(-344.14 -281.26)" fill={fill} />
          <path d="M496.35,305.42c0,.8.21,1.11.77,1.14s.65.54.62,1-.36.85-1.18.85a2,2,0,0,1-2.2-1.86,5.24,5.24,0,0,1-4.47,1.94c-3.33,0-5.47-2-5.47-5.34,0-3.7,2.48-5.84,6-5.84s5.89,2.22,5.89,5.73Zm-5.89-6.37a3.67,3.67,0,0,0-3.92,3.95,3.45,3.45,0,0,0,3.72,3.69,3.76,3.76,0,0,0,4-3.85A3.55,3.55,0,0,0,490.46,299.05Z" transform="translate(-344.14 -281.26)" fill={fill} />
      </g>  
      </svg>
      <svg
        width="23"
        height="22.06"
        viewBox="0 0 23 22.06"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
      <circle cx="11.5" cy="1.57" r="1.57" fill={fill} />
      <circle cx="5.71" cy="1.57" r="1.57" fill={fill} />
      <circle cx="17.29" cy="1.57" r="1.57" fill={fill} />
      <path d="M11.4,8.55a4,4,0,0,1,3.33,6.14l-3,4.55a4,4,0,0,1-6.66,0l-3-4.55A4,4,0,0,1,5.47,8.55H11.4m0-1.49H5.47A5.46,5.46,0,0,0,.9,15.5l3,4.55a5.46,5.46,0,0,0,9.15,0l3-4.55A5.46,5.46,0,0,0,11.4,7.06Z" transform="translate(0 -0.47)" fill={fill} />
      <path d="M17.53,8.55a4,4,0,0,1,3.33,6.14l-3,4.55a4,4,0,0,1-6.66,0l-3-4.55A4,4,0,0,1,11.6,8.55h5.93m0-1.49H11.6A5.46,5.46,0,0,0,7,15.5l3,4.55a5.46,5.46,0,0,0,9.15,0l3-4.55a5.46,5.46,0,0,0-4.57-8.44Z" transform="translate(0 -0.47)" fill={fill} />
      </svg>
        
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
